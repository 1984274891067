<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="mt-5"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          icon="mdi-account-outline"
          inline
          title="编辑个人资料"
          class="mb-0 px-2 px-sm-5 px-md-5 px-lg-5 px-xl-5 py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 mt-12"
        >
          <!-- <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              编辑个人资料
            </div>
          </template> -->
          <v-alert
            dense
            outlined
            type="success"
            class="py-0 mt-3 px-0 px-sm-3 px-md-3 px-lg-3 px-xl-3"
          >
            用户ID：<strong>{{ userId }}</strong>
          </v-alert>
          <div class="userPhoto">
            <v-row class="d-flex justify-center align-center my-3">
              <img
                :src="avatar ? avatar : userPhoto"
                height="150px"
              >
            </v-row>
            <div>
              <Upload
                :action="fileUploadUrl"
                :headers="fileUploadheader"
                :on-success="fileUpload"
                :show-upload-list="false"
              >
                <Button icon="ios-cloud-upload-outline">
                  点击上传头像
                </Button>
              </Upload>
            </div>
          </div>
          <v-form>
            <v-row
              style="width:100%"
              :class="isXs?'titleSearch':''"
            >
              <v-col
                cols="12"
                md="12"
              >
                <input
                  v-model="avatarId"
                  type="hidden"
                >
                <v-text-field
                  v-model="nickName"
                  label="昵称"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="office"
                  class="purple-input"
                  label="部门"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="motto"
                  class="purple-input"
                  label="职位"
                />
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="mobile"
                  label="手机号"
                  class="purple-input"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-radio-group
                  v-model="sex"
                  row
                >
                  <v-radio
                    label="男"
                    value="0"
                  />
                  <v-radio
                    label="女"
                    value="1"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="success"
                  class="mr-0"
                  @click="uploadUserInfo"
                >
                  保存
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-overlay
      :value="uploadSuccess"
    >
      <v-card
        light
      >
        <v-card-text
          class="text-center"
        >
          <v-icon
            x-large
            medium
            color="error"
          >
            fa fa-exclamation-triangle
          </v-icon><br>
          <h3
            color="error"
            class="pt-5"
          >
            信息修改成功。
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            @click="uploadSuccess = false"
          >
            知道了
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
  import userPhoto from '../../assets/user_photo.jpg'
  export default {
    data () {
      return {
        userPhoto: userPhoto,
        overlay: false,
        uploadSuccess: false,
        uploadError: false,
        userId: '',
        nickName: '',
        avatar: '',
        avatarId: '',
        email: '',
        mobile: '',
        office: '',
        motto: '',
        sex: '0',
        birthday: '',
        token: {},
        fileUploadUrl: 'https://zdwz.api.sdcom.gov.cn/api/files',
        fileUploadheader: {},
        imgName: '',
        visible: false,
        uploadList: [],
        isXs: false,
      }
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    created () {
      this.getUserInfo()
      this.getToken()
      this.token.Authorization = localStorage.token
    },
    methods: {
      async getUserInfo () {
        const UserInfoData = await this.$axios.get('/me')
        const UserInfo = UserInfoData.data.data
        this.userId = UserInfo.id ? UserInfo.id : ''
        this.nickName = UserInfo.nickname ? UserInfo.nickname : ''
        this.office = UserInfo.office ? UserInfo.office : ''
        this.avatar = UserInfo.avatar ? UserInfo.avatar : ''
        this.email = UserInfo.email ? UserInfo.email : ''
        this.mobile = UserInfo.mobile ? UserInfo.mobile : ''
        this.motto = UserInfo.motto ? UserInfo.motto : ''
        this.sex = UserInfo.sex >= 0 ? UserInfo.sex.toString() : ''
        this.birthday = UserInfo.birthday ? UserInfo.birthday : ''
      },
      uploadUserInfo () {
        this.overlay = true
        this.$axios.put(`/users/${this.userId}`, {
          avatar: this.avatar,
          birthday: this.birthday,
          email: this.email,
          mobile: this.mobile,
          nickName: this.nickName,
          office: this.office,
          motto: this.motto,
          sex: this.sex,
        }).then(res => {
          this.overlay = false
          this.$swal({
            title: '更改成功!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          this.getUserInfo()
        }).catch(err => {
          this.$swal({
            title: '更改失败!',
            icon: 'error',
            text: '原因:' + err.response.data.msg,
            confirmButtonText: '知道了',
          })
          this.getUserInfo()
          this.overlay = false
        })
      },
      getToken () {
        this.fileUploadheader['authorization'] = localStorage.token
        this.fileUploadheader['x-app'] = localStorage.xApp
      },
      fileUpload (response, file, fileList) {
        this.avatar = response.data.url
        this.uploadUserInfo()
      },
    },
  }
</script>
<style scoped>
.userPhoto{
  height: 200px;
  text-align: center;
}
.userPhoto img{
  float: left;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}
.changePhoto{
  float: left;
  margin-top: 60px;
  margin-left: 40px;
  width: 600px;
  text-align: left;
}
::v-deep .v-alert--dense .v-alert__wrapper i{
margin-right:0px
}
::v-deep .titleSearch .v-text-field__details{
display:none
}
</style>
